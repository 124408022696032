.toolbar-container {
  margin-top: 15px;
}

.toolbar-container .toolbar-right {
  text-align: right;
  width: 100%;
}

.toolbar-container .toolbar-right > button {
  margin-left: 8px;
}

.toolbar-container .toolbar-left {
  text-align: left;
  width: 100%;
}

.toolbar-container .toolbar-left > button {
  margin-right: 8px;
}
