.header {
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 100%;
}

.header .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
