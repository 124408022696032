@import '~antd/dist/antd.css';

body {
  margin: 0;
  padding: 0;
  background-color: #f3f3f3;
}

#root {
  height: 100%;
}

.text-center {
  text-align: center;
}

.ant-modal-footer > .toolbar-container {
  margin-top: 0;
}
