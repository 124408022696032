.main-container {
  display: flex;
  min-height: 100vh;
  overflow-y: auto;
}

.content {
  height: 100%;
  margin: 25px 0;
  max-width: 1200px;
  padding: 0 50px;
  width: 100%;
}

.content.without-padding {
  margin-bottom: 0;
  margin-top: 64px;
  padding: 0;
}

@media only screen and (max-width: 576px) {
  .content {
    padding: 0 10px;
  }
}
