.row-title {
  margin-bottom: 15px;
  margin-top: 15px;
  padding-left: 5px;
}

.row-title-content {
  border-bottom: 1px solid #d9d9d9;
  box-shadow: 0 1px rgba(249, 248, 248, 0.74);
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
}
